import React, { useContext } from "react"
import { navigate } from "gatsby"
import tw from "twin.macro"
import styled from "@emotion/styled"
import { motion } from "framer-motion"

import MenuItem from "../DropdownItem"
import { GlobalStateContext, GlobalDispatchContext } from "../../context/global-context-provider"

import * as Color from "../../utils/color"
import { mobile, tablet, desktop } from "../../utils/breakpoint"
import { Hybrid } from "../Devices"

import { navigations } from "../../meta/navigation"

const SubItem = styled(motion.li)`
	${tw`mt-1`}
`

const SubList = styled(motion.ol)``

const Header = styled(motion.h6)`
	${tw`text-base`}
	font-weight: bold;
	color: ${Color.primary};
`

const Item = styled.li`
	min-height: 42px;
`

const List = styled.ol`
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(276px, 1fr));
	grid-gap: 16px;
`

const Content = styled.div`
	padding: 2em 0;
`

const Inner = styled.div`
	@media ${mobile} {
		padding: 0 2em;
	}

	@media ${tablet} {
		padding: 0 4em;
	}

	@media ${desktop} {
		padding: 0 6em;
	}
`

const Menu = styled(motion.div)`
	background-color: white;
	border-bottom: solid 1px lightgray;
`

const headerVariants = {
	hide: {
		transitionEnd: {
			y: "-20px",
			opacity: 0,
		},
	},
	show: {
		y: "0px",
		opacity: 1,
	},
}

const subItemVariants = {
	hide: {
		transitionEnd: {
			x: "-20px",
			opacity: 0,
		},
	},
	show: {
		x: "0px",
		opacity: 1,
	},
}

const TabletMenu = React.forwardRef((props, ref) => {
	const { showMenu } = useContext(GlobalStateContext)
	const dispatch = useContext(GlobalDispatchContext)

	return (
		<Hybrid>
			<Menu ref={ref} {...props}>
				<Inner>
					<Content>
						<List>
							{navigations.map((item, index) => {
								return (
									<Item>
										<Header
											animate={showMenu ? "show" : "hide"}
											variants={headerVariants}
											transition={{ delay: 0.08 * index }}
											onClick={() => {
												navigate(`${item.path}`)
												dispatch({ type: "HIDE_MENU" })
											}}
										>
											{item.pathname}
										</Header>
										<SubList>
											{"children" in item &&
												item.children.sibling.map((sub, index) => {
													return (
														<SubItem
															animate={showMenu ? "show" : "hide"}
															variants={subItemVariants}
															transition={{ delay: 0.18 * index }}
														>
															<MenuItem
																title={sub.pathname}
																detail={sub.detail}
																icon={sub.icon}
																onClick={() => navigate(sub.path)}
															/>
														</SubItem>
													)
												})}
										</SubList>
									</Item>
								)
							})}
						</List>
					</Content>
				</Inner>
			</Menu>
		</Hybrid>
	)
})

export default motion.custom(TabletMenu)
