import React, { useContext } from "react"
import styled from "@emotion/styled"

import { FaBars } from "react-icons/fa"
import { GlobalDispatchContext } from "../../context/global-context-provider"

import * as Color from "../../utils/color"
import { Hybrid } from "../Devices"

const MenuButton = styled.button`
	width: 42px;
	height: 42px;
	background-color: ${Color.primary_200};
	border-radius: calc(42px / 2);
	display: flex;
	justify-content: center;
	align-items: center;

	> svg {
		fill: ${Color.primary};
	}
`

const HybridNavigation = () => {
	const dispatch = useContext(GlobalDispatchContext)

	return (
		<Hybrid>
			<MenuButton onClick={() => dispatch({ type: "TOGGLE_MENU" })} aria-label={"toggle menu"}>
				<FaBars size={16} />
			</MenuButton>
		</Hybrid>
	)
}

export default HybridNavigation
