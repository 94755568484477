import React from "react"
import Media from "react-media"

import { desktop } from "../../utils/breakpoint"

const Desktop = ({ children, ...props }) => {
	return <Media query={desktop}>{children}</Media>
}

export default Desktop
