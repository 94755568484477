import React from "react"
import { Link } from "gatsby"
import tw from "twin.macro"
import styled from "@emotion/styled"

import Fluid from "../Fluid"
import FooterImage from "../../assets/svg/footer-pic.svg"

import * as Color from "../../utils/color"
import { mobile, tablet, desktop } from "../../utils/breakpoint"

import { navigationGroups } from "../../meta/navigation"

const BottomContent = styled.div`
	${tw`text-sm`};

	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`

const NavItem = styled.li`
	${tw`text-sm`}
	padding-bottom: 0.28em;
`

const NavTitle = styled.div`
	${tw`text-lg`};
	font-weight: bold;
`

const NavList = styled.ul`
	padding-top: 0.28em;
`

const NavGroup = styled.div``

const Navigations = styled.div`
	display: grid;
	grid-area: navigations;
	grid-gap: 1em;

	@media ${mobile} {
		grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
	}

	@media ${tablet} {
		grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
	}

	@media ${desktop} {
		margin-left: 100px;
		grid-template-columns: repeat(4, 1fr);

		${NavGroup}:nth-of-type(1) {
			grid-column-end: -4;
		}
	}
`

const Brand = styled.div`
	> h6 {
		${tw`text-xl`}
		font-weight: bold;
		padding-bottom: 0.28em;
	}

	> div {
		${tw`text-sm`}
	}

	grid-area: brand;
`

const TopContent = styled.div`
	width: 100%;
	height: 100%;

	display: grid;
	padding: 2em 0;
	grid-row-gap: 32px;

	@media ${mobile} {
		grid-template-areas: "brand" "navigations";
	}

	@media ${tablet} {
		grid-template-areas: "brand" "navigations";
	}

	@media ${desktop} {
		grid-template-areas: "brand navigations";
		grid-template-columns: 1fr 2fr;
	}
`

const BottomBox = styled.div`
	color: white;
	background-color: ${Color.primary};
`

const TopBox = styled.div`
	padding: 2em 0;
	color: white;
	background-color: ${Color.primary};
`

const ImageHolder = styled.div``

const Footer = styled.footer`
	padding-top: 8em;
	display: grid;
	grid-template-rows: auto auto 64px;
`

const NavigationFooter = () => {
	return (
		<Footer>
			<ImageHolder>
				<FooterImage />
			</ImageHolder>
			<TopBox>
				<Fluid>
					<TopContent>
						<Brand>
							<h6>Arrowdot</h6>
							<div>
								ArrowDot Co., Ltd. is an Automation Company working on Industrial Control System, IOT
								System, and Electronics Product Development since 2012. Our qualified Engineers are able
								to design, fabricate, and install both hardware and software for your system with
								quality assurance.
							</div>
						</Brand>
						<Navigations>
							{navigationGroups.map(group => {
								return (
									<NavGroup key={group.id}>
										<NavTitle>{group.title}</NavTitle>
										<NavList>
											{group.list.map(item => (
												<NavItem key={item.id}>
													<Link to={item.path}>{item.pathname}</Link>
												</NavItem>
											))}
										</NavList>
									</NavGroup>
								)
							})}
						</Navigations>
					</TopContent>
				</Fluid>
			</TopBox>
			<BottomBox>
				<BottomContent>
					<span>© Copyright 2020. Arrowdot. All Rights Reserved.</span>
				</BottomContent>
			</BottomBox>
		</Footer>
	)
}

export default NavigationFooter
