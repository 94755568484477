import React from "react"
import tw from "twin.macro"
import { css } from "@emotion/core"
import styled from "@emotion/styled"

import * as Color from "../../utils/color"

const Button = styled.button`
	${tw`text-xs md:text-sm focus:outline-none hover:outline-none`}
	min-height: 42px;
	font-weight: bold;
	text-transform: uppercase;
	border-radius: 8px;

	color: white;
	background-color: ${Color.primary};

	${props =>
		props.transparent &&
		css`
			border: solid 1px lightgray;
			background-color: transparent;

			&:hover {
				color: ${Color.primary};
				background-color: white;
			}
		`}

	${props =>
		props.outline &&
		css`
			color: ${Color.primary};
			border: solid 1px ${Color.primary};
			background-color: transparent;

			&:hover {
				color: white;
				background-color: ${Color.primary};
			}
		`}

	&:hover {
		filter: brightness(1.1);
	}

	&:active {
		filter: brightness(0.9);
	}
`

const ButtonWrapper = ({ children, ...props }) => {
	return <Button {...props}>{children}</Button>
}

export default styled(ButtonWrapper)``
