const small = "(max-width: 599px)"
const medium = "(min-width: 600px) and (max-width: 1199px)"
const large = "(min-width: 1200px)"

const smallToMedium = "(max-width: 1199px)"

export {
	small as mobile,
	medium as tablet,
	large as desktop,
	smallToMedium as hybrid,
}
