import React, { useContext, useState } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import tw from "twin.macro"
import styled from "@emotion/styled"
import { AnimatePresence, motion } from "framer-motion"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"

import DesktopNav from "./desktop-navigation"
import HybridNav from "./hybrid-navigation"
import HybridMenu from "./hybrid-menu"
import Fluid from "../Fluid"

import { GlobalStateContext, GlobalDispatchContext } from "../../context/global-context-provider"

import * as Color from "../../utils/color"
import ArrowdotIcon from "../../assets/svg/arrowdot-icon.svg"

const Nav = styled.nav`
	height: inherit;
	display: grid;
	grid-template-areas: "left right right right";
`

const LeftBox = styled.div`
	grid-area: left;
	display: inherit;
	align-items: center;
`

const RightBox = styled.div`
	grid-area: right;
	display: inherit;
	align-items: center;
`

const LeftContent = styled.div`
	${tw`text-xl`}

	> a {
		display: flex;
	}
`

const RightContent = styled.div`
	display: flex;
	justify-content: flex-end;
`

const Header = styled(motion.header)`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 64px;
	z-index: 100;

	background-color: white;
`

const Arrow = styled.span``

const Dot = styled(motion.span)``

const Brand = styled(motion.h6)`
	${tw`text-xl`}
	position: relative;
	top: 11px;
	color: ${Color.primary};
	font-weight: bold;
`

const Icon = styled.span``

const navigationVariants = {
	hide: {
		top: "-128px",
		opacity: 0,
	},
	show: {
		top: "0px",
		opacity: 1,
	},
}

const menuVariants = {
	hide: {
		height: 0,
	},
	show: {
		height: "auto",
	},
}

const dotVariants = {
	init: {
		color: Color.primary,
	},
	then: {
		color: Color.secondary,
	},
}

export default function NavigationBar() {
	const { showMenu } = useContext(GlobalStateContext)
	const dispatch = useContext(GlobalDispatchContext)
	const [hideOnScroll, setHideOnScroll] = useState(true)
	const [brandHover, setBrandHover] = useState(false)

	useScrollPosition(
		({ prevPos, currPos }) => {
			const isShow = currPos.y > prevPos.y
			const triggerPosY = currPos.y <= -64

			if (isShow !== hideOnScroll && triggerPosY) {
				setHideOnScroll(isShow)
			}

			if (!isShow) dispatch({ type: "HIDE_MENU" })
		},
		[hideOnScroll],
		false,
		false
	)

	return (
		<>
			<Header
				animate={hideOnScroll ? "show" : "hide"}
				variants={navigationVariants}
				transition={{ duration: 0.4, ease: "easeInOut" }}
			>
				<Fluid>
					<Nav>
						<LeftBox>
							<LeftContent>
								<Link to="/">
									<Icon>
										<ArrowdotIcon style={{ width: 42, height: 42 }} />
									</Icon>
									<Brand
										onMouseOver={() => setBrandHover(true)}
										onMouseLeave={() => setBrandHover(false)}
									>
										<Arrow>Arrow</Arrow>
										<Dot
											animate={brandHover ? "then" : "init"}
											variants={dotVariants}
											transition={{ duration: 0.2 }}
										>
											dot
										</Dot>
									</Brand>
								</Link>
							</LeftContent>
						</LeftBox>
						<RightBox>
							<RightContent>
								<DesktopNav />
								<HybridNav />
							</RightContent>
						</RightBox>
					</Nav>
				</Fluid>
				<AnimatePresence>
					{showMenu && (
						<HybridMenu
							initial={"hide"}
							animate={showMenu ? "show" : "hide"}
							exit={"hide"}
							variants={menuVariants}
							transition={{ duration: 0.4 }}
						/>
					)}
				</AnimatePresence>
			</Header>
		</>
	)
}
