import React from "react"
import { FaPlug, FaMicrochip, FaWrench, FaAtom, FaCarBattery, FaIndustry } from "react-icons/fa"

export let navigations = [
	{
		id: "home",
		path: "/",
		pathname: "Home",
	},
	{
		id: "academy",
		path: "/academy",
		pathname: "Academy",
		children: {
			parent_id: "academy",
			title: "Courses",
			sibling: [
				{
					id: "academy_basic",
					path: "/academy/courses/basic-arduino-program",
					pathname: "Basic Arduino Program",
					detail: "focus on basic electronic, and arduino",
					icon: <FaPlug size={22} />,
				},
				{
					id: "academy_advanced",
					path: "/academy/courses/advanced-arduino-program",
					pathname: "Advanced Arduino Program",
					detail: "building desktop and mobile application to communicate with arduino",
					icon: <FaMicrochip size={22} />,
				},
				{
					id: "academy_plc",
					path: "/academy/courses/plc-program",
					pathname: "PLC Program",
					detail: "build big complex circuit for control the electronic",
					icon: <FaIndustry size={22} />,
				},
			],
		},
	},
	{
		id: "solutions",
		pathname: "Solutions",
		path: "/solutions",
		children: {
			parent_id: "solutions",
			title: "Provides",
			sibling: [
				{
					id: "solution_electronic_and_iot",
					pathname: "Electronic | IOT Solution ",
					path: "/solutions/electronic-and-iot",
					icon: <FaAtom size={22} />,
				},
				{
					id: "solution_automation_and_robotic",
					pathname: "Automation | Robotic Solution",
					path: "/solutions/automation-and-robotic",
					icon: <FaCarBattery size={22} />,
				},
			],
		},
	},
	{
		id: "products",
		path: "/products",
		pathname: "Products",
	},
	{
		id: "news",
		path: "/news",
		pathname: "News",
	},
	{
		id: "about_us",
		path: "/about-us",
		pathname: "About us",
	},
	{
		id: "contact_us",
		path: "/contact-us",
		pathname: "Contact us",
	},
]

export let navigationGroups = [
	{
		id: "group_academy",
		title: "Academy",
		list: [
			{
				id: "footer_academy_basic",
				path: "/academy/courses/basic-arduino-program",
				pathname: "Basic Arduino Program",
			},
			{
				id: "footer_academy_advanced",
				pathname: "Advanced Arduino Program",
				path: "/academy/courses/advanced-arduino-program",
			},
			{
				id: "footer_academy_plc",
				pathname: "PLC Program",
				path: "/academy/courses/plc-program",
			},
		],
	},
	{
		id: "group_solutions",
		title: "Solutions",
		list: [
			{
				id: "footer_solution_electronic",
				pathname: "Electronic and IOT Solution",
				path: "/solutions/electronic-and-iot",
			},
			{
				id: "footer_solution_education",
				pathname: "Automation and Robotic Solution",
				path: "/solutions/automation-and-robotic",
			},
		],
	},
	{
		id: "group_updates",
		title: "Updates",
		list: [
			{
				id: "footer_news",
				pathname: "News",
				path: "/news",
			},
			{
				id: "footer_products",
				pathname: "Products",
				path: "/products",
			},
		],
	},
	{
		id: "group_about",
		title: "About",
		list: [
			{
				id: "footer_contact_us",
				pathname: "Contact us",
				path: "/contact-us",
			},
			{
				id: "footer_about_us",
				pathname: "About us",
				path: "/about-us",
			},
		],
	},
]
