module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/root/bro_dora/Projects/arrowdot.com/frontend/src/templates/layout.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-embedder","options":{"customTransformers":[],"services":{}}}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"arrowdot.io","short_name":"arrowdot","start_url":"/","lang":"en","background_color":"#ffffff","theme_color":"#1D71B8","display":"standalone","icon":"src/assets/images/logo.png","crossOrigin":"use-credentials","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"a78be3ab9cc5d42013d8231a898c6d71"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
