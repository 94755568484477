import React, { useReducer } from "react"

const GlobalStateContext = React.createContext({})
const GlobalDispatchContext = React.createContext()

const defaultState = {
	showMenu: false,
}

const globalReducer = (state, action) => {
	switch (action.type) {
		case "TOGGLE_MENU": {
			return { ...state, showMenu: !state.showMenu }
		}

		case "HIDE_MENU": {
			return { ...state, showMenu: false }
		}

		default: {
			return { ...state }
		}
	}
}

const GlobalProvider = ({ children }) => {
	const [state, dispatch] = useReducer(globalReducer, defaultState)

	return (
		<GlobalStateContext.Provider value={state}>
			<GlobalDispatchContext.Provider value={dispatch}>{children}</GlobalDispatchContext.Provider>
		</GlobalStateContext.Provider>
	)
}

export default GlobalProvider
export { GlobalStateContext, GlobalDispatchContext }
