import styled from "@emotion/styled"
import { mobile, tablet, desktop } from "../../utils/breakpoint"

const Fluid = styled.div`
	height: inherit;

	@media ${mobile} {
		padding: 0 2em;
	}

	@media ${tablet} {
		padding: 0 4em;
	}

	@media ${desktop} {
		padding: 0 6em;
	}
`

export default styled(Fluid)``
