import React, { useState } from "react"
import { navigate } from "gatsby"
import tw from "twin.macro"
import styled from "@emotion/styled"
import { motion, AnimateSharedLayout } from "framer-motion"
import { useLocation } from "@reach/router"

import DropItem from "../DropdownItem"

import * as Color from "../../utils/color"
import { Desktop } from "../Devices"

import { navigations } from "../../meta/navigation"

const DropDown = styled(motion.ol)`
	min-width: 240px;
	position: absolute;
	top: 32px;
	right: 0;

	z-index: 200;
	background-color: white;
	border: solid 1px lightgray;
	border-radius: 8px;

	padding-bottom: 0.5em;

	${DropItem} {
		margin: 0.4em 0.4em 0;
	}
`

const DropTitle = styled(motion.h6)`
	${tw`text-lg`}
	color: ${Color.primary};
	font-weight: bold;
	padding: 0.8em 0.6em 0.4em;
`

const Underline = styled(motion.div)`
	position: absolute;
	width: 100%;
	height: 4px;
	background: ${Color.primary};
	bottom: -8px;
	border-radius: 4px;
`

const Pathname = styled(motion.div)`
	font-weight: ${props => (props.active ? "bold" : "normal")};
`

const Navigate = styled(motion.a)`
	display: flex;
	position: relative;
	color: ${Color.primary} !important;
	cursor: pointer;
`

const Item = styled(motion.li)`
	position: relative;
	user-select: none;
`

const List = styled.ol`
	display: flex;
	transition: translateZ(0px);

	${Item}:first-of-type {
		padding-right: 1em;
	}

	${Item}:not(:last-of-type) {
		padding: 0 1em;
	}

	${Item}:last-of-type {
		padding-left: 1em;
	}
`

const DesktopNavigation = () => {
	const location = useLocation()
	const [isDropdown, setIsDropDown] = useState(false)
	const [dropList, setDropList] = useState(null)
	const [delayHandler, setDelayHandler] = useState(null)

	const mouseOverHandler = item => {
		if ("children" in item) {
			clearTimeout(delayHandler)
			setDropList(item.children)
			setIsDropDown(true)
		}
	}

	const mouseLeaveHandler = () => {
		setDelayHandler(
			setTimeout(() => {
				setIsDropDown(false)
			}, 200)
		)
	}

	return (
		<Desktop>
			<AnimateSharedLayout>
				<List>
					{navigations.map((item, index) => {
						return (
							<Item
								animate
								key={index}
								onMouseOver={() => mouseOverHandler(item)}
								onMouseLeave={mouseLeaveHandler}
							>
								<Navigate key={item.id} onClick={() => navigate(item.path)}>
									<Pathname active={item.path === location.pathname}>{item.pathname}</Pathname>
									{item.path === location.pathname && <Underline layoutId={"underline"} />}
								</Navigate>

								{isDropdown && "children" in item && item.children.parent_id === dropList.parent_id && (
									<DropDown>
										<DropTitle>{item.children.title}</DropTitle>
										{item.children.sibling.map((item, index) => {
											return (
												<DropItem
													key={index}
													as="li"
													title={item.pathname}
													detail={item.detail}
													icon={item.icon}
													onClick={() => navigate(item.path)}
													style={{
														backgroundColor:
															item.path === location.pathname ? Color.primary_100 : "",
													}}
												/>
											)
										})}
									</DropDown>
								)}
							</Item>
						)
					})}
				</List>
			</AnimateSharedLayout>
		</Desktop>
	)
}

export default DesktopNavigation
