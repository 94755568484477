import React from "react"
import tw from "twin.macro"
import styled from "@emotion/styled"

import Button from "../Button"
import { FaTimes } from "react-icons/fa"

import * as Color from "../../utils/color"

const Message = styled.div`
	${tw`text-base md:text-lg`}
	width: 80%;
	color: white;
	font-weight: bold;
	text-align: center;
`

const Banner = styled.div`
	width: 100%;
	height: 88px;
	background-color: ${Color.primary};

	display: flex;
	justify-content: center;
	align-items: center;

	${Button} {
		${tw`ml-4 mr-4`}
		border-radius: 20px;
		width: 40px;
		height: 40px;

		display: flex;
		justify-content: center;
		align-items: center;
	}
`

const BannerWrapper = ({ message = "message supposed to be here, you know!", clickHandler, ...props }) => {
	return (
		<Banner>
			<Message>{message}</Message>
			<Button onClick={clickHandler} aria-label={"close banner"}>
				<FaTimes size={18} />
			</Button>
		</Banner>
	)
}

export default BannerWrapper
