import React from "react"
import Media from "react-media"

import { hybrid } from "../../utils/breakpoint"

const Hybrid = ({ children, ...props }) => {
	return <Media query={hybrid}>{children}</Media>
}

export default Hybrid
