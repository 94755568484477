const primary = "#1D71B8"
const secondary = "#CF3A3B"
const tertiary = "#F5EE9E"

const primary_100 = "rgba(29, 113, 184, 0.1)"
const primary_200 = "rgba(29, 113, 184, 0.2)"
const primary_300 = "rgba(29, 113, 184, 0.3)"
const primary_400 = "rgba(29, 113, 184, 0.4)"
const primary_500 = "rgba(29, 113, 184, 0.5)"
const primary_600 = "rgba(29, 113, 184, 0.6)"
const primary_700 = "rgba(29, 113, 184, 0.7)"
const primary_800 = "rgba(29, 113, 184, 0.8)"
const primary_900 = "rgba(29, 113, 184, 0.9)"

export { primary, secondary, tertiary }
export {
	primary_100,
	primary_200,
	primary_300,
	primary_400,
	primary_500,
	primary_600,
	primary_700,
	primary_800,
	primary_900,
}
