import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Global, css } from "@emotion/core"
import styled from "@emotion/styled"

import Header from "../components/Header"
import Footer from "../components/Footer"
import Banner from "../components/Banner"

const globalStyles = css`
	html,
	body {
		width: 100%;
		height: 100%;
	}

	body {
		overflow-x: initial;
	}

	#___gatsby {
		width: 100%;
		height: 100%;
	}

	#gatsby-focus-wrapper {
		width: 100%;
		height: 100%;
	}
`

const Layout = styled.div`
	height: 100%;
	display: grid;

	> *:last-child {
		align-self: flex-end;
	}
`

const Main = styled.main`
	margin-top: 64px;
`

const bannerMessageCreator = courses => {
	const message = ""
	const openingCourses = []

	courses.forEach(({ name, banner }) => {
		if ("message" in banner && "isOpenForRegister" in banner) {
			if (banner.message != "null") openingCourses.push(banner.message)
			else if (banner.isOpenForRegister) openingCourses.push(name)
		}
	})

	openingCourses.filter(item => item != null)

	return {
		message: openingCourses.join(" + ").concat(" is now opening!"),
		hasNoOpening: openingCourses.length == 0,
	}
}

const LayoutWrapper = props => {
	const { children, pageContext } = props
	const [showBanner, setShowBanner] = useState(true)

	const data = useStaticQuery(graphql`
		{
			allStrapiCourses(sort: { order: ASC, fields: order }) {
				edges {
					node {
						slug
						name
						banner {
							message
							isOpenForRegister
						}
					}
				}
			}
		}
	`)

	const courses = []
	data.allStrapiCourses.edges.forEach(item => courses.push(item.node))
	const { message, hasNoOpening } = bannerMessageCreator(courses)

	switch (pageContext.layout) {
		case "404":
			return (
				<>
					<Global styles={globalStyles} />
					{children}
				</>
			)

		default:
			return (
				<>
					<Global styles={globalStyles} />
					<Layout>
						<Header />
						<Main>
							{showBanner && !hasNoOpening && (
								<Banner message={message} clickHandler={() => setShowBanner(false)} />
							)}
							{children}
						</Main>
						<Footer />
					</Layout>
				</>
			)
	}
}

export default LayoutWrapper
