import React from "react"
import tw from "twin.macro"
import styled from "@emotion/styled"
import { motion } from "framer-motion"

import * as Color from "../../utils/color"

const Detail = styled.p`
	${tw`text-xs mt-1`}
	color: ${Color.primary};
`

const Title = styled.h6`
	${tw`text-sm leading-none`}
	font-weight: bold;
	color: ${Color.primary};
`

const IconHolder = styled.div`
	width: 42px;
	height: 42px;
	background-color: ${Color.primary_200};
	border-radius: calc(42px / 2);

	display: flex;
	justify-content: center;
	align-items: center;

	svg {
		fill: ${Color.primary};
	}
`

const RightBox = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	min-height: 42px;
`

const LeftBox = styled.div`
	flex-basis: 42px;
	margin-right: 0.6em;
`

const Content = styled.div`
	display: flex;
	padding: 0.4em 0;
`

const Inner = styled.div`
	padding: 0 0.6em;
`

const Item = styled(motion.div)`
	border-radius: 8px;
	min-height: 56px;
	cursor: pointer;
`

const DropdownItem = React.forwardRef(({ title, detail, icon, ...props }, ref) => {
	return (
		<Item {...props} ref={ref}>
			<Inner>
				<Content>
					{icon && (
						<LeftBox>
							<IconHolder>{icon}</IconHolder>
						</LeftBox>
					)}
					<RightBox>
						<Title>{title}</Title>
						{detail && <Detail>{detail}</Detail>}
					</RightBox>
				</Content>
			</Inner>
		</Item>
	)
})

export default styled(motion.custom(DropdownItem))``
